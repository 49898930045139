<template>
  <div class="nav-sidebar" :class="{ 'open': $store.state.app.navOpen }">
    <a class="close" @click="closeNav"></a>
    <div class="sidebar-content__wrapper">
      <v-img :src="`https://yourcardonline.co.za/files/img/sidebar-header.jpeg`"></v-img>
      <h1 class="sidebar-content__heading">Adopt YOURCard today and benefit from the features that it offers.</h1>
      <p>YOURCard is an interactive digital business card that works on smartphones, tablets, and computers. It enables you to create a digital network where customers can be informed about yourself and your business.</p>

      <p>You can share YOURCard with ease, while you allow prospects and customers to connect with you directly. They can call, email or WhatsApp you by the touch of a button!</p>

      <p>Sharing YOURCard is simple. Clients can scan the QR code at the bottom of the card or alternatively click on the “Share Business Card” button and choose from any of the available sharing methods. It is that easy to connect.</p>
      
      <p>Each card is tailored to your brand with a choice of a Lite or Pro card. Cards include standard contact information, a business and/or personal summary, a photo, a link to your business website plus all your social networks in one place. YOURCard can also seamlessly integrate with Zoho CRM, to manage all your client requests, messages, and brand traction.</p>

      <h3>Why YOURCard?</h3>
      <ul>
        <li>Unlimited sharing from anywhere.</li>
        <li>Direct client communication.</li>
        <li>Instant navigation to business office.</li>
        <li>Contactless information sharing.</li>
        <li>Quick access to social media profiles.</li>
        <li>Never be without a business card again.</li>
        <li>Eliminate printing costs.</li>
        <li>Increased support to a greener business model.</li>
        <li>Enhanced brand value.</li>
      </ul>
      <br>
      <p>Be different and start inspiring your clients, digitally.</p>
    </div>
    <div class="sidebar-content__btn-wrapper">
      <v-btn href="http://yourcardonline.co.za/plans/" class="btn__website">Plans</v-btn>
      <v-btn href="http://yourcardonline.co.za/our-features/" class="btn__website">Features</v-btn>
      <v-btn href="http://yourcardonline.co.za/fqa/" class="btn__website">FAQ</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data: () => ({
      publicPath: process.env.BASE_URL
    }),
  methods: {
    closeNav() {
      setTimeout(() => {
        this.$store.dispatch("app/navOpen", {
          navOpen: false
        })
      }, 0)
    },
  }
}
</script>

<style lang="scss" scoped>
$primary-light: #B3181D;
$primary-dark: rgb(100, 14, 17);
$secondary: #6f686b;
$tertiary: #303030;
$white: #ffffff;
$body-background: #fff;
$branding: #3D1E60;

// responsive
$tablet: 768px;
$desktop: 1200px;
$large-desktop: 1440px;

// box shadow
$box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);


.nav-sidebar {
  background: $body-background;
  min-height: 100vh;
  padding: 65px 0 15px;
  position: fixed;
  transform: translateX(-100vw);
  transition: transform 0.5s ease-in-out;
  width: 100vw;
  z-index: 20;
  height: 100%;
  overflow-y: scroll;

  &.open {
    transform: translateX(0px);
    box-shadow: 1px 1px 10px #4a4a4a;
  }
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 32px;
  height: 32px;
}

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #000;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.sidebar-content {

  &__wrapper {
    padding: 0 15px;
    max-width: 500px;
    margin: 0 auto;
  }

  &__heading {
    text-align: center;
    margin-bottom: 30px;
  }

  &__btn-wrapper {
    margin: 30px auto;
    display: flex;
    justify-content: space-around;
    max-width: 400px;
  }
}
</style>